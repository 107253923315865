import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HashLocationStrategy,LocationStrategy ,
  CommonModule
} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { adminComponent } from './layouts/admin/admin.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LoginGuard } from './login.guard';
import { NoLoginGuard } from './no-login.guard';
import {DatePipe} from '@angular/common';
import { NgxLoadingModule } from 'ngx-loading';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { FIREBASE_CREDENTIALS } from './firebase.credentials';
import { AutocompleteModule } from 'ng2-input-autocomplete';
import { SidebarAdminComponent } from './shared/sidebaradmin/sidebaradmin.component';



//import { NgCulqiModule } from 'ng-culqi';



const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    adminComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent
    
   //SidebarAdminComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    AngularFirestoreModule,
    RouterModule.forRoot(Approutes, { useHash: true }),
    NgxLoadingModule.forRoot({}),
    AutocompleteModule,
    PerfectScrollbarModule,
    //NgCulqiModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBdbulZpGbNXKhukqpLhugwXXCyPIoR8oc',
      libraries: ['places']
    })
  ],
  providers: [
    {
      provide: LocationStrategy,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      useClass: HashLocationStrategy
    },
	{ provide: FirestoreSettingsToken, useValue: {} },LoginGuard,NoLoginGuard,DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
