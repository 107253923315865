import { Component,ChangeDetectorRef, AfterViewInit, EventEmitter, Output } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
//import { Notification } from 'src/app/model/notification.model';
import * as firebase from 'firebase';
import { Router, ActivatedRoute } from '@angular/router';
//import { AngularFirestore,AngularFirestoreCollection  } from 'angularfire2/firestore';
import { MoviesService } from "./MoviesService.service";

import { Observable } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {
	//notifications = new Array<Notification>();
	searchValue: string = "";
	results: any;
	@Output() toggleSidebar = new EventEmitter<void>();
	items: Observable<any[]>;
	public config: PerfectScrollbarConfigInterface = {};
	ref = firebase.firestore().collection('notifications');
	refEmpresa = firebase.firestore().collection('empresa');
	  firestore = firebase.firestore();
	public showSearch = false;
	ArrayOptional = [];
  IDEMPRESA;
  tipousuario;
	nrSelectEmpresa;
	nombreDireccion;
	selectedItem: any = '';
	inputChanged: any = '';
	wikiItems: any[] = [];
	message;
  items2: any[] = [{id: 0, payload: {label: ''}}];
  correo;
  foto;
  
  config2: any = {'placeholder': 'test', 'sourceField': ['payload', 'label']};

  listaEmpresa=[];
//private msgService: MoviesService,
//  constructor(private msgService: MoviesService,private modalService: NgbModal,public afs: AngularFirestore) {
  constructor(private msgService: MoviesService,private cdr: ChangeDetectorRef,private modalService: NgbModal,private router: Router,) {


    //this.items=afs.collection('movies').valueChanges();
    //this.booksCollection = afs.collection('movies');
    
   // this.fetch();

  }

  fetch() {
	  
	  /*
   
    this.booksCollection.valueChanges().subscribe((books) => {
      //this.bookStore.set(books);
      console.log(books);

      var  data1 =books;
      this.items2=[];
      for (var key2 in data1) {
               
        console.log("REPORTE extrar - " +key2 +" "+books[key2]['Plot']);

         
          this.items2.push({id: books[key2]['Plot'], payload: {label: books[key2]['Title']}});


          } 

     

    });*/

  }

  // This is for Notifications
  /*notifications: Object[] = [
    {
      btn: 'btn-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      btn: 'btn-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      btn: 'btn-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      btn: 'btn-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];*/


  // This is for Mymessages

  mymessages: Object[] = [

    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    }
  ];

  /*
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];*/

  ngAfterViewInit() {
    console.log("modificacion");

	this.msgService.getPermission();
    this.msgService.receiveMessage();
    this.message = this.msgService.currentMessage;
	
	console.log("mensaje")
	console.log(this.message)
	
    const request = JSON.parse(localStorage.getItem('users'));
    

    console.log("modificacion" +request.nombres);
    console.log("modificacion" +request.idEmpresa);
    console.log(request);
    let today = Date.now();
    let  userId=request.providerId;
	  this.IDEMPRESA=request.idEmpresa;
   
    this.tipousuario=request.tipousuario;
    
    this.correo=request.nombres +"";
    if(request.photoURL !=''){
      
      // this.foto=request.photoURL;

       setTimeout(() => {
        this.foto = 'https://lh3.googleusercontent.com/a-/AOh14GhdVi4uyoF4CvyGfDIVFlQ482o2BELryY1I7Rl__g=s96-c';
        this.cdr.detectChanges(); // Trigger change detection manually
      }, 0);

    }else {
       this.foto='assets/images/users/1.jpg';
    }

	 // this.usuarioCuenta=request.usuarioCuenta;
     this.listarEmpresa(  this.tipousuario,request.usuarioCuenta, this.IDEMPRESA);
	 
	 
	 
	 
	 
	 /*
        firebase.database().ref('notifications/'+ userId).push({
          btn: 'btn-danger',
          icon: 'ti-link',
          title: 'Luanch Admin',
          subject: 'Just see the my new admin!',
          time: today
              })

             
    let listaProducto = [];
    firebase.database().ref('notifications/'+ userId).on('value',function (Snapshot){

     
 
        if(listaProducto.length>0){
          listaProducto.length = 0;
       
        }

      Snapshot.forEach(function(e) { 
       var data=e.val();
        var keyProducto =e.key;
       listaProducto.push({
              ID:keyProducto,
              btn:data.btn,
              icon: data.icon,
              title: data.title,
              subject: data.subject,
              time: data.time,
       })
      } 
      
      );
      document.getElementById("Notifi-close").click();   
     
  });

    
    this.notifications=listaProducto;
	*/

  }

  
 listarEmpresa(tipousuario,usuarioCuenta,IDEMPRESA) {

  

    this.listaEmpresa=JSON.parse(localStorage.getItem('listaEmpresa'));
    const users = JSON.parse(localStorage.getItem('users'));


    for (let datav of this.listaEmpresa) {


      
      let idWeek = {
        name: datav.direccion,
        value:this.IDEMPRESA,
        direccion: datav.direccion,
            direccionFiscal: datav.direccionFiscal,
            empresa: datav.empresa,
            fotoempresa: datav.fotoempresa,
            latitude: datav.latitude,
            longitude: datav.longitude,
            principal: datav.principal,
            ruc: datav.ruc,
            telefono: datav.telefono,
            serieboleta:datav.serieboleta,
            seriefactura:datav.seriefactura,
            idempresanew:datav.idempresanew

      };

       if(datav.idempresanew===users.idempresanew) {
        //  alert("ingreso")
          this.nombreDireccion=datav.direccion;

        }
        
      
      this.ArrayOptional.push(idWeek);

    }




 }


 listarEmpresa2(tipousuario,usuarioCuenta,IDEMPRESA) {
	  
		var valorCategoria=0
		
    console.log("**Empresa *"+this.IDEMPRESA);
    
  //  alert(tipousuario);
    //alert(usuarioCuenta);
    console.log("**usuarioCuenta : "+usuarioCuenta);
    this.ArrayOptional=[];
		if(tipousuario=='Admin'){
     
            this.firestore.collection('empresa').where('usuarioCuenta','==',usuarioCuenta).get().then(snapshot => {
              snapshot.forEach(data => {
                let datav = data.data();
                console.log("cambio de usuario : "+datav);
                console.log(datav);
              /*  var idWeek = {
                    name: datav.direccion,
                    value: data.id
                }*/

                let idWeek = {
                  name: datav.direccion,
                  value: data.id,
									direccion: datav.direccion,
                  direccionFiscal: datav.direccionFiscal,
                  empresa: datav.empresa,
                  fotoempresa: datav.fotoempresa,
                  latitude: datav.latitude,
                  longitude: datav.longitude,
                  principal: datav.principal,
                  ruc: datav.ruc,
                  telefono: datav.telefono,
                  serieboleta:datav.serieboleta,
                  seriefactura:datav.seriefactura

								};
                
                console.log("listar empresa" +data.id +"this.IDEMPRESA:"+this.IDEMPRESA);
                if(data.id==this.IDEMPRESA) {
                //  alert("ingreso")
                  this.nombreDireccion=datav.direccion;

                }
               

                
                this.ArrayOptional.push(idWeek);
            });
              });
              
          }else {
                    
          this.firestore.doc('empresa/'+IDEMPRESA).get().then(snapshot => {
            let datav = snapshot.data();	
           console.log(datav);
          console.log("cambio de usuario : "+datav);
            this.nombreDireccion=datav.direccion;
           /* var idWeek = {
                name: datav.direccion,
                value: this.IDEMPRESA
            }*/


            let idWeek = {
              name: datav.direccion,
              value:this.IDEMPRESA,
              direccion: datav.direccion,
                  direccionFiscal: datav.direccionFiscal,
                  empresa: datav.empresa,
                  fotoempresa: datav.fotoempresa,
                  latitude: datav.latitude,
                  longitude: datav.longitude,
                  principal: datav.principal,
                  ruc: datav.ruc,
                  telefono: datav.telefono,
                  serieboleta:datav.serieboleta,
                  seriefactura:datav.seriefactura,
                  idempresanew:datav.idempresanew

            };
            
            this.ArrayOptional.push(idWeek);

            });

          }

        console.log(  this.ArrayOptional)
			  
	  
  }
 

  onSelect(item: any) {
    console.log("item");
    console.log(item);
    this.selectedItem = item;
  }
 
  onInputChangedEvent(val: string) {
    console.log("val" +val);
    /*

    this.booksCollection = this.afs.collection('movies', ref => ref
    .orderBy("Title")
    .startAt(val)
    .endAt(val+"\uf8ff")
    .limit(10));

    this.fetch();
    this.inputChanged = val;*/
  }

  searchv (term: string) {
    //this.service.search(term).subscribe(e => this.wikiItems = e, error => console.log(error));
  }
  
  async onChangeEmpresa(){
	  
  }

  cambiarFoto(){
   

  }
  cambiarEmpresa(data){
    
    console.log(data);
    
    this.nombreDireccion=data.name;
    const request = JSON.parse(localStorage.getItem('users'));

    const userv =  {
        phoneNumber: request.phoneNumber,
        photoURL: request.photoURL,
        nombres:request.nombres,
        email:request.email,
        telefono:request.telefono,
        usuarioCuenta:request.usuarioCuenta,
        providerId:request.providerId,
        tokenmessaging: request.tokenmessaging,
        idEmpresa:data.value,
        tipousuario:request.tipousuario,
        principal:request.principal,
        perfil:request.perfil,
        MODULO:request.MODULO,
        PERMISO:request.PERMISO,
        PAGINA:request.PAGINA,
        idempresanew:data.idempresanew,

    } ;

    const empresa = {
      direccion: data.direccion,
      direccionFiscal: data.direccionFiscal,
      direccionLocal: data.direccionLocal==null?"":data.direccionLocal,
      empresa: data.empresa,
      fotoempresa: data.fotoempresa,
      latitude: data.latitude,
      longitude: data.longitude,
      principal: data.principal,
      ruc: data.ruc,
      telefono: data.telefono,
      serieboleta:data.serieboleta,
      seriefactura:data.seriefactura,
      idempresanew:data.idempresanew,

    };
    console.log(empresa);
   // this.localJson = userv;
  
    localStorage.setItem('users', JSON.stringify(userv));
    localStorage.setItem('empresa', JSON.stringify(empresa));

   

    location.reload(true);

  }

    logout() {
  
    localStorage.removeItem('users');
    localStorage.removeItem('admin');
    this.router.navigate(['/authentication/login']);
  }

}
