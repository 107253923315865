import { RouteInfo } from './sidebar.metadata';



export const ROUTES: RouteInfo[] = [
  
  {
    path: '',
    title: 'Graficas',
    icon: 'mdi mdi-view-dashboard',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/dashboard/classic',
        title: 'Classic',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      },
      
      {
        path: '/dashboard/analytical',
        title: 'Analytical',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: true, //ocultar true
        submenu: []
      },
      {
        path: '/dashboard/cryptocurrency',
        title: 'Cryptocurrency',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: true,
        submenu: []
      },
      {
        path: '/dashboard/overview',
        title: 'Overview',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: true,
        submenu: []
      },
      {
        path: '/dashboard/ecommerce',
        title: 'Ecommerce',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: true,
        submenu: []
      },
      {
        path: '/dashboard/sale',
        title: 'Sale',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: true,
        submenu: []
      },
      {
        path: '/dashboard/general',
        title: 'General',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: true,
        submenu: []
      },
      {
        path: '/dashboard/trendy',
        title: 'Trendy',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: true,
        submenu: []
      },
      {
        path: '/dashboard/campaign',
        title: 'Campaign',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: true,
        submenu: []
      },
      {
        path: '/dashboard/modern',
        title: 'Modern',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: true,
        submenu: []
      },
      
      {
        path: '/dashboard/reporte',
        title: 'Reporte',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      },

      {
        path: '/dashboard/ticketentregar',
        title: 'Ticket Por Entregar',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      },

    ]
    
  },
  {
    path: '',
    title: 'Ventas',
    icon: 'mdi mdi-cart-outline',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/ecom/products',
        title: 'Products',
        icon: 'mdi mdi-cards-variant',
        class: '',
        extralink: true,
        submenu: []
      },
      {
        path: '/ecom/cierrecaja',
        title: 'Cierre de Caja',
        icon: 'mdi mdi-cards-variant',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/ecom/cart',
        title: 'Nuevo Orden',
        icon: 'mdi mdi-cart',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/ecom/pendientelavado',
        title: 'Pendiente Lavado',
        icon: 'mdi mdi-cart-plus',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/ecom/lavadofinalizado',
        title: 'Lavado Finalizado',
        icon: 'mdi mdi-camera-burst',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/ecom/prendarecogida',
        title: 'Prenda Recogida',
        icon: 'mdi mdi-chart-pie',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/ecom/historialpedido',
        title: 'Historial Pedido',
        icon: 'mdi mdi-chart-pie',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/ecom/edit',
        title: 'Limpio',
        icon: 'mdi mdi-cart-plus',
        class: '',
        extralink: true,
        submenu: []
      },
      {
        path: '/ecom/details',
        title: 'Listo',
        icon: 'mdi mdi-camera-burst',
        class: '',
        extralink: true,
        submenu: []
      },
      {
        path: '/ecom/orders',
        title: 'Recogidas',
        icon: 'mdi mdi-chart-pie',
        class: '',
        extralink: true,
        submenu: []
      },
      {
        path: '/ecom/checkout',
        title: 'Checkout',
        icon: 'mdi mdi-clipboard-check',
        class: '',
        extralink: true,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Configuracion',
    icon: 'mdi mdi-gradient',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/apps/chat',
        title: 'Productos',
        icon: 'mdi mdi-comment-processing-outline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/apps/fullcalendar',
        title: 'Categoria',
        icon: 'mdi mdi-calendar',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/apps/taskboard',
        title: 'Imagenes',
        icon: 'mdi mdi-bulletin-board',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/apps/impresora',
        title: 'impresora',
        icon: 'mdi mdi-bulletin-board',
        class: '',
        extralink: false,
        submenu: []
      }
     
    ]
  },
  {
    path: '',
    title: 'My Perfil',
    icon: 'mdi mdi-file',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/sample-pages/profile',
        title: 'Editar Perfile',
        icon: 'mdi mdi-account-network',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/sample-pages/pricing',
        title: 'Pricing',
        icon: 'mdi mdi-file-export',
        class: '',
        extralink: true,
        submenu: []
      },
      {
        path: '/sample-pages/invoice',
        title: 'Invoice',
        icon: 'mdi mdi-ungroup',
        class: '',
        extralink: true,
        submenu: []
      },
      {
        path: '/sample-pages/helperclasses',
        title: 'Helper Classes',
        icon: 'mdi mdi-tune',
        class: '',
        extralink: true,
        submenu: []
      },
      {
        path: '/sample-pages/employee',
        title: 'Empleados',
        icon: 'mdi mdi-tune',
        class: '',
        extralink: false,
        submenu: []
      }
	  
    ]
  },

  {
    path: '',
    title: 'Cliente',
    icon: 'mdi mdi-account-circle',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/cliente/micliente',
        title: 'micliente',
        icon: 'mdi mdi-account-key',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Boleta',
    icon: 'mdi mdi-account-circle',
    class: 'has-arrow',
    extralink: true,
    submenu: [
      {
        path: '/boleta/miboleta',
        title: 'Miboleta',
        icon: 'mdi mdi-account-key',
        class: '',
        extralink: true,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Administrador',
    icon: 'mdi mdi-account-circle',
    class: 'has-arrow',
    extralink: true,
    submenu: [
      {
        path: '/admin/miscliente',
        title: 'miscliente',
        icon: 'mdi mdi-account-key',
        class: '',
        extralink: true,
        submenu: []
      }
    ]
  }
  

  
];
