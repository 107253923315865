import { Component } from '@angular/core';
import {
  Router, NavigationStart, NavigationEnd,
  NavigationCancel, NavigationError, Event
} from '@angular/router';
import * as firebase from 'firebase';
import { Title, Meta } from '@angular/platform-browser';

const settings = {timestampsInSnapshots: true};
const config = {
  apiKey: "AIzaSyBdbulZpGbNXKhukqpLhugwXXCyPIoR8oc",
    authDomain: "lavanderia-cdce7.firebaseapp.com",
    //databaseURL: "https://lavanderia-cdce7.firebaseio.com",
    databaseURL: "https://app.taqsay.com",
    projectId: "lavanderia-cdce7",
    storageBucket: "lavanderia-cdce7.appspot.com",
    messagingSenderId: "232063093839"
 
};


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Sistema Lavandería y Tintorería | TaqsayTaqsay - Lavanderia sistemas ';

  constructor(
    private titleService: Title,
    private meta: Meta
    ) {}

  ngOnInit() {
    firebase.initializeApp(config);
    this.titleService.setTitle(this.title);
    this.meta.addTags([
      {name: 'keywords', content: this.title},
      {name: 'description', content: this.title},
      {name: 'robots', content: this.title}
    ]);
    //firebase.firestore().settings(settings);
    
  }

}
