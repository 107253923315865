import { Component, AfterViewInit, OnInit,HostListener } from '@angular/core';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as jQuery from 'jquery';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})


export class SidebarComponent implements OnInit {
  public showMobileMenu = false;
	showMenu = '';
	showSubMenu = '';
	adminLogin;
	tipousuario;
	DASHBOARD;
	ECOMMERCE;
	PRODUCTO;
	CONFIGURACION;
	
	CLASICO;
	SALE;
	NUEVAORDEN;
	LIMPIO;
	LISTO;
	RECOGIDAS;
	PRODUCTOS;
	SECCIONES;
	INVENTORY;

	
  public sidebarnavItems: any[];
  correo=''
  foto='assets/images/users/1.jpg';
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
   // alert("addActive");
console.log("element"+element);
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      // estamos desde un movil o tablet
      jQuery("#main-wrapper").removeClass("show-sidebar")
      jQuery('#ti_menu').click();
      }else {

        console.log("hola2");
      }
    //  this.showSubMenu = element;
   
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
     
    } else {
      this.showSubMenu = element;
    
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  // End open close
  ngOnInit() {
	
    const request = JSON.parse(localStorage.getItem('users'));
	this.tipousuario=request.tipousuario;

	
	this.DASHBOARD=request.MODULO.DASHBOARD;
	this.ECOMMERCE=request.MODULO.ECOMMERCE;
	this.PRODUCTO=request.MODULO.PRODUCTO;
	this.CONFIGURACION=request.MODULO.CONFIGURACION;
	
	
	this.CLASICO=request.PERMISO.CLASICO;
	this.SALE=request.PERMISO.SALE;
	this.NUEVAORDEN=request.PERMISO.NUEVAORDEN;
	this.LIMPIO=request.PERMISO.LIMPIO;
	this.LISTO=request.PERMISO.LISTO;
	this.RECOGIDAS=request.PERMISO.RECOGIDAS;
	this.PRODUCTOS=request.PERMISO.PRODUCTOS;
	this.SECCIONES=request.PERMISO.SECCIONES;
	this.INVENTORY=request.PERMISO.INVENTORY;
	

	
  
    console.log("this.DASHBOARD:"+this.DASHBOARD);
    //this.correo=request.correo;
    //steve@gmail.com
    this.correo=request.nombres +"";
	if(request.photoURL !=''){
		
		 this.foto=request.photoURL;
	}else {
		 this.foto='assets/images/users/1.jpg';
	}
   
  // alert("router"+this.Dashboards);
  // console.log(ROUTES);

    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
	
	console.log(this.sidebarnavItems);
	
		for (var key in this.sidebarnavItems) {
			
			const titleGeneral= this.sidebarnavItems[key]['title'];
			
			if(titleGeneral=='Dashboards') {
				
				
				
    
				this.sidebarnavItems[key]['extralink']=!this.DASHBOARD;
				let submenu= this.sidebarnavItems[key]['submenu'];
			  	for (var key1 in submenu) {
					const submenud= submenu[key1]['title']; 
					 if(submenud=='Classic') {
						 this.sidebarnavItems[key]['submenu'][key1]['extralink']=!this.CLASICO;  
					 }
					  if(submenud=='Sale') { 
						 this.sidebarnavItems[key]['submenu'][key1]['extralink']=!this.SALE;  
					 }
				}
			}
			if(titleGeneral=='Ecommerce Pages') {
				this.sidebarnavItems[key]['extralink']=!this.ECOMMERCE;
				let submenuv= this.sidebarnavItems[key]['submenu'];
			  	for (var key1 in submenuv) {
					const submenu= submenuv[key1]['title']; 
					 if(submenu=='Nuevo Orden') {
						 this.sidebarnavItems[key]['submenu'][key1]['extralink']=!this.NUEVAORDEN;  
					 }
					  if(submenu=='Limpio') { 
						 this.sidebarnavItems[key]['submenu'][key1]['extralink']=!this.LIMPIO;  
					 }
					   if(submenu=='Listo') { 
						 this.sidebarnavItems[key]['submenu'][key1]['extralink']=!this.LISTO;  
					 }
					   if(submenu=='Recogidas') { 
						 this.sidebarnavItems[key]['submenu'][key1]['extralink']=!this.RECOGIDAS;  
					 }
				}
			}
			
			if(titleGeneral=='Productos') {
				this.sidebarnavItems[key]['extralink']=!this.PRODUCTO;
				let submenuv1= this.sidebarnavItems[key]['submenu'];
			  	for (var key1 in submenuv1) {
					const submenu= submenuv1[key1]['title']; 
					 if(submenu=='Productos') {
						 this.sidebarnavItems[key]['submenu'][key1]['extralink']=!this.PRODUCTOS;  
					 }
					  if(submenu=='Secciones') { 
						 this.sidebarnavItems[key]['submenu'][key1]['extralink']=!this.SECCIONES;  
					 }
					   if(submenu=='Inventory') { 
						 this.sidebarnavItems[key]['submenu'][key1]['extralink']=!this.INVENTORY;  
					 }
					   
				}
			}
			
			if(titleGeneral=='Configuracion') {
				this.sidebarnavItems[key]['extralink']=!this.CONFIGURACION;
				
			}

			if(titleGeneral=='Administrador') {
				const requestAdmin = JSON.parse(localStorage.getItem('admin'));
				console.log(requestAdmin);

				this.adminLogin=requestAdmin.estado;

				//alert("admin"+this.adminLogin);
				
				if(this.adminLogin=='admin'){
					
					this.sidebarnavItems[key]['extralink']=false;
				}else {
					
					this.sidebarnavItems[key]['extralink']=true;
				}

				
				
			}
			  
			  //alert("router"+infoDAY);
			//console.log("infoDAY"+infoDAY);
			
		}
	 console.log(this.sidebarnavItems);
	 
	 
	 //if(this.DASHBOARD){
					 $('#Dashboards').hide(); 
		//		}
				
				
  }
  logout() {
  
    localStorage.removeItem('users');
	localStorage.removeItem('admin');
    this.router.navigate(['/authentication/login']);
  }

  ocultarMenu() {

  }

}
