import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root' 
})
export class ConfiguracionGuard implements CanActivate {
  constructor (private router :Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

 if(localStorage.getItem('users')===null) {
	    this.router.navigate(['/authentication/login']);
	 return false 
 }else {
const request = JSON.parse(localStorage.getItem('users'));
		const CONFIGURACION=request.MODULO.CONFIGURACION;
		
	  if(!CONFIGURACION) {
		  
		  alert("No tiene Permiso");
	  }

		
		 return  CONFIGURACION;
		 
 }
    
  }
}
