
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MoviesService {

	messaging = firebase.messaging();
	currentMessage = new BehaviorSubject(null);
	firestore = firebase.firestore();
  
  constructor() { }

  
   //https://github.com/firebase/quickstart-js/blob/69fabc373fbbd2a724e49464c74f8cc5fd87c520/messaging/index.html#L84-L85
  
    addToken(token) {
   // alert("addToken"+token);
	/*	
   const request = JSON.parse(localStorage.getItem('users'));
		console.log(request.usuarioCuenta);
		this.firestore.collection('cliente').doc(request.usuarioCuenta).update({
		"TOKENMESSAGING": token}) .catch((err) => {
		  
		 this.firestore.collection("ERROR").add({ERROR: err});


        console.log('Unable to get permission to notify.', err);
		
      });;
		*/
	

	
  }
  
   getPermission() {
    
	   const messaging = firebase.messaging();
	 
	/*  messaging.requestPermission().then(function() {
      console.log('Notification permission granted.');
      // TODO(developer): Retrieve an Instance ID token for use with FCM.
      return messaging.getToken();
      // ...
    }).then(function (token ){
      console.log('tokend.'+token);
	  
	  this.addToken(token);

    }).catch(function(err) {
      console.log('Unable to get permission to notify.', err);
    });*/
	
	
	/* 
	messaging.requestPermission()
      .then(() => {
        console.log('Notification permission granted.');
        return messaging.getToken()
      })
      .then(token => {
        console.log(token)
        //this.addToken(token);
      })
      .catch((err) => {
		  
		 this.firestore.collection("ERROR").add({ERROR: err});


        console.log('Unable to get permission to notify.', err);
		
      });
	  */
	
    }

  receiveMessage() {
	   const messaging = firebase.messaging();
	   
      messaging.onMessage((payload) => {
        console.log("Message received. ", payload);
        this.currentMessage.next(payload)
      });

    }
}