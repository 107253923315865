import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
//import { AngularFirestore,AngularFirestoreCollection  } from 'angularfire2/firestore';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class adminComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};

//constructor(public router: Router,public afs: AngularFirestore) {}
  constructor(public router: Router) {}

  tabStatus = 'justified';

  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;

  options = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // fixed value. shouldn't be changed.
    sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin6' // six possible values: skin(1/2/3/4/5/6)
  };

  detalleShow=true;
 // booksCollection: AngularFirestoreCollection;
  selectedItem: any = '';
  inputChanged: any = '';
  wikiItems: any[] = [];

  items2: any[] = [{id: 0, payload: {label: ''}}];
  
  config2: any = {'class': 'test', 'max': 2,'placeholder': 'test', 'sourceField': ['payload', 'label']};


  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    //alert("hola")

    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      // estamos desde un movil o tablet
     this.detalleShow=false;
      }else {

        this.detalleShow=true;
      }
	//alert(this.router.url);
    if (this.router.url === '/') {
      this.router.navigate(['/authentication/login']);
    }
	
	 if (this.router.url === '/#/') {
      this.router.navigate(['/authentication/login']);
    }
    this.defaultSidebar = this.options.sidebartype;
    this.handleSidebar();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case 'full':
      case 'iconbar':
        if (this.innerWidth < 1170) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case 'overlay':
        if (this.innerWidth < 767) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case 'full':
      case 'iconbar':
        this.options.sidebartype = 'mini-sidebar';
        break;

      case 'overlay':
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case 'mini-sidebar':
        if (this.defaultSidebar === 'mini-sidebar') {
          this.options.sidebartype = 'full';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  

  onSelect(item: any) {
   
    this.selectedItem = item;
  }
 
  onInputChangedEvent(val: string) {
    console.log("val" +val);
    
/*
    this.booksCollection = this.afs.collection('movies', ref => ref
    .orderBy("Title")
    .startAt(val)
    .endAt(val+"\uf8ff")
    .limit(10));

    this.fetch();
    this.inputChanged = val;*/
  }


}
