import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NoLoginGuard implements CanActivate {
  constructor (private router :Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		
			
      if(localStorage.getItem('users')===null) {
      
        return true;
      }else {
		const request = JSON.parse(localStorage.getItem('users'));
		const PAGINA='/'+request.PAGINA;
		//alert(PAGINA);
		
        this.router.navigate([PAGINA]);
        return false;
      }
  }
}
