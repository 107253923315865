import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { adminComponent } from './layouts/admin/admin.component';

import { LoginGuard } from './login.guard';
import { NoLoginGuard } from './no-login.guard';
import { DashboardGuard } from './dashboard.guard';
import { ConfiguracionGuard } from './configuracion.guard';
import { EcommerceGuard } from './ecommerce.guard';
import { ProductoGuard } from './producto.guard';


export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/authentication/login', pathMatch: 'full', canActivate: [NoLoginGuard] },

      {
        path: 'dashboard',
        loadChildren: './dashboards/dashboard.module#DashboardModule', canActivate: [DashboardGuard]
      },

      { path: 'ecom', loadChildren: './ecommerce/ecom.module#EcomModule', canActivate: [EcommerceGuard] },
      { path: 'apps', loadChildren: './apps/apps.module#AppsModule', canActivate: [ProductoGuard] },
      { path: 'cliente', loadChildren: './cliente/cliente.module#ClienteModule', canActivate: [ProductoGuard] },
      {
        path: 'sample-pages',
        loadChildren: './sample-pages/sample-pages.module#SamplePagesModule', canActivate: [ConfiguracionGuard]
      }
     
    ]
  },
  {
    path: '',
    component: adminComponent,  // FullComponent adminComponent
    children: [
     
        {
          path: 'admin',
          loadChildren:
            './admin/admin.module#AdminModule'
        }
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'adminlogin',
        loadChildren:
          './adminlogin/adminlogin.module#AdminloginModule', canActivate: [NoLoginGuard]
        }
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule', canActivate: [NoLoginGuard]
        },
        {
          path: 'boleta',
          loadChildren:
            './boleta/boleta.module#BoletaModule'
        }
    ]
  },
 
  {
    path: '**',
    redirectTo: '/authentication/404'
  }
];
