import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blank-layout',
  templateUrl: './blank.component.html',
  styleUrls: []
})
export class BlankComponent {

  constructor(public router: Router) {}
  ngOnInit() {
    
	//alert(this.router.url +"blank");
   
    if (this.router.url === '/#/') {
      this.router.navigate(['/authentication/login']);
    }
	
    if (this.router.url === '/') {
      this.router.navigate(['/authentication/login']);
    }
    if (this.router.url === '/#authentication/login') {
      this.router.navigate(['/authentication/login']);
    }
    if (this.router.url === '/#authentication/signup') {
      this.router.navigate(['/authentication/signup']);
    }

    
  }

}
