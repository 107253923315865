import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DashboardGuard implements CanActivate {
	constructor(private router: Router) { }
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		//localStorage.getItem('users')

		if (localStorage.getItem('users') === null) {
			this.router.navigate(['/authentication/login']);
			return false
		} else {
			const request = JSON.parse(localStorage.getItem('users'));

			console.log(request);
			console.log("this.DASHBOARD" + request);
			const DASHBOARD = request.MODULO.DASHBOARD;
			if (!DASHBOARD) {

				alert("No tiene Permiso");
			}


			console.log("this.DASHBOARD" + DASHBOARD);

			return DASHBOARD;

		}


	}
}
